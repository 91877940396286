<template>
  <div>
    <loading :is-loading="isLoading"/>
    <template v-if="content">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-12" style="margin-top: 25px;">
            <table class="certificate-data-table table table-bordered">
              <tbody>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">វិញ្ញាបនបត្រលេខ</p>
                </td>
                <td class="font-khmer">
                  <template v-if="content.issued_number_km">
                    <button class="btn btn-link pl-0 pt-0 font-khmer" @click="showDocument">
                      {{ content.issued_number_km }}
                    </button>
                  </template>
                  <template v-else>
                    <p>-</p>
                  </template>
                  <p>ចុះ{{ dateFormatKhmer(content.issued_date) }}</p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Certificate N<sup>o</sup></p>
                </td>
                <td class="font-en">
                  <template v-if="content.issued_number_en">
                    <button class="btn btn-link pl-0 pt-0 font-en" @click="showDocument">
                      {{ content.issued_number_en }}
                    </button>
                  </template>
                  <template v-else>
                    <p>-</p>
                  </template>
                  <p v-if="content.issued_date">Dated {{ dateFormat(content.issued_date) }}</p>
                  <p v-else>Dated -</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">សំណង់​នៅលើ​​ក្បាល​ដី​លេខ</p>
                </td>
                <td class="font-khmer">
                  <p>{{ content.land_number_km ? content.land_number_km : '-' }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Construction on the land parcel N<sup>o</sup></p>
                </td>
                <td class="font-en">
                  <p>{{ content['land_number_en'] ? content['land_number_en'] : '-' }}</p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">អាសយដ្ឋាន</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data" v-html="content['address_km'] ? content['address_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Address</p>
                </td>
                <td class="font-en">
                  <p class="text-data" v-html="content['address_en'] ? content['address_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">ម្ចាស់សំណង់</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data" v-html="content['owner_km'] ? content['owner_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Construction Owner</p>
                </td>
                <td class="font-en">
                  <p class="text-data" v-html="content['owner_en'] ? content['owner_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">មុខងារ​ប្រើប្រាស់​សំណង់</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data" v-html="content['project_type_km'] ? content['project_type_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Construction Occupancy</p>
                </td>
                <td class="font-en">
                  <p class="text-data" v-html="content['project_type_en'] ? content['project_type_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">ផ្ទៃក្រឡា​កម្រាល​សំណង់​សរុប</p>
                </td>
                <td class="font-khmer">
                  <p>{{ content['total_area_km'] ? content['total_area_km'] + ' ម៉ែត្រក្រឡា' : '-' }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Total Floor Area</p>
                </td>
                <td class="font-en">
                  <p>{{ content['total_area_en'] ? content['total_area_en'] + ' square meters' : '-' }}</p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">លិខិត​អនុញ្ញាត​សាងសង់​លេខ</p>
                </td>
                <td class="font-khmer">
                  <p>{{ content['permit_number_km'] ? content['permit_number_km'] + content['suffix_building_permit_number'] : '-' }}</p>
                  <p>ចុះ{{ dateFormatKhmer(content['permit_date']) }}</p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Building Permit N<sup>o</sup></p>
                </td>
                <td class="font-en">
                  <p>{{ content['permit_number_en'] ? content['permit_number_en'] : '-' }}</p>
                  <p v-if="content['permit_date']">Dated {{ dateFormat(content['permit_date']) }}</p>
                  <p v-else>Dated -</p>
                </td>
              </tr>
              <tr class="bg-table" v-if="content['revised_building_permit_km']">
                <td class="font-khmer">
                  <p class="symbol">លិខិត​អនុញ្ញាត​កែសម្រួល​ប្លង់​លេខ</p>
                </td>
                <td class="font-khmer">
                  <p>{{ content['permit_number_km'] ? content['permit_number_km'] + ' ដនស/អដន' : '-' }}</p>
                  <p>ចុះ{{ dateFormatKhmer(content['revised_building_permit_date']) }}</p>
                </td>
              </tr>
              <tr class="bg-table" v-if="content['revised_building_permit_en']">
                <td class="font-en">
                  <p class="symbol">Revised Building Permit N<sup>o</sup></p>
                </td>
                <td class="font-en">
                  <p>{{ content['revised_building_permit_en'] ? content['revised_building_permit_en'] : '-' }}</p>
                  <p v-if="content['revised_building_permit_date']">Dated {{ dateFormat(content['revised_building_permit_date']) }}</p>
                  <p v-else>Dated -</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">លិខិត​អនុញ្ញាត​បើក​ការដ្ឋាន​សំណង់​លេខ</p>
                </td>
                <td class="font-khmer">
                  <p>
                    {{ content['open_construction_number_km'] ? content['open_construction_number_km'] + ' ' + content['open_construction_number_suffix'] : '-' }}</p>
                  <p>ចុះ{{ dateFormatKhmer(content['open_construction_date']) }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Construction Site Opening Permit N<sup>o</sup></p>
                </td>
                <td class="font-en">
                  <p>{{ content['open_construction_number_en'] ? content['open_construction_number_en'] : '-' }}</p>
                  <p v-if="content['open_construction_date']">Dated
                    {{ dateFormat(content['open_construction_date']) }}</p>
                  <p v-else>Dated -</p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">សម្រង់​ប្លង់​តាម​ការ​សាងសង់​ជាក់ស្ដែង​លេខ</p>
                </td>
                <td class="font-khmer">
                  <p>{{ content['building_drawing_number_km'] ? content['building_drawing_number_km'] : '-' }}</p>
                  <p>ចុះ{{ dateFormatKhmer(content['building_drawing_date']) }}</p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">As-build drawing(s) N<sup>o</sup></p>
                </td>
                <td class="font-en">
                  <p>{{ content['building_drawing_number_en'] ? content['building_drawing_number_en'] : '-' }}</p>
                  <p v-if="content['building_drawing_date']">Dated
                    {{ dateFormat(content['building_drawing_date']) }}</p>
                  <p v-else>Dated -</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">លិខិត​បញ្ជាក់​អនុលោមភាព​លេខ</p>
                </td>
                <td class="font-khmer">
                  <p>
                    {{ content['certificate_compliance_number_km'] ? content['certificate_compliance_number_km'] + ' ' + content['certificate_compliance_number_suffix'] : '-' }}</p>
                  <p>ចុះ{{ dateFormatKhmer(content['certificate_compliance_date']) }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Certificate of Compliance N<sup>o</sup></p>
                </td>
                <td class="font-en">
                  <p>
                    {{ content['certificate_compliance_number_et'] ? content['certificate_compliance_number_et'] : '-' }} {{ content['certificate_of_compliance_number_suffix_en'] ? content['certificate_of_compliance_number_suffix_en'] : '' }}
                  </p>
                  <p v-if="content['certificate_compliance_date']">Dated
                    {{ dateFormat(content['certificate_compliance_date']) }}</p>
                  <p v-else>Dated -</p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">បញ្ជាក់អនុលោមភាពដោយ</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data" v-html="content['compliance_by_km'] ? content['compliance_by_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Issued by</p>
                </td>
                <td class="font-en">
                  <p class="text-data" v-html="content['compliance_by_en'] ? content['compliance_by_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">ប្លង់​ស្ថាបត្យកម្ម​សិក្សា​ដោយ</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data"
                     v-html="content['architecture_design_by_km'] ? content['architecture_design_by_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Architecture Design by</p>
                </td>
                <td class="font-en">
                  <p class="text-data"
                     v-html="content['architecture_design_by_en'] ? content['architecture_design_by_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">ប្លង់ស្ថាបត្យកម្មត្រួតពិនិត្យនិងបញ្ជាក់ដោយ</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data"
                     v-html="content['architecture_design_reviewed_by_km'] ? content['architecture_design_reviewed_by_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Architecture Design Reviewed by</p>
                </td>
                <td class="font-en">
                  <p class="text-data"
                     v-html="content['architecture_design_reviewed_by_en'] ? content['architecture_design_reviewed_by_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">ប្លង់គ្រឿង​ផ្គុំ​សំណង់​អំពី</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data"
                     v-html="content['structural_design_km'] ? content['structural_design_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Structural Design</p>
                </td>
                <td class="font-en">
                  <p class="text-data"
                     v-html="content['structural_design_en'] ? content['structural_design_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">សិក្សាដោយ</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data"
                     v-html="content['structural_design_by_km'] ? content['structural_design_by_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Structural Design by</p>
                </td>
                <td class="font-en">
                  <p class="text-data"
                     v-html="content['structural_design_by_en'] ? content['structural_design_by_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">គ្រឿងផ្គុំសំណង់ត្រួតពិនិត្យនិងបញ្ជាក់ដោយ</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data"
                     v-html="content['structural_design_reviewed_by_km'] ? content['structural_design_reviewed_by_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Structural Design Reviewed by</p>
                </td>
                <td class="font-en">
                  <p class="text-data"
                     v-html="content['structural_design_reviewed_by_en'] ? content['structural_design_reviewed_by_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">ប្រព័ន្ធមេកានិក អគ្គិសនី និងទឹកត្រួតពិនិត្យនិងបញ្ជាក់ដោយ</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data" v-html="content['mep_system_km'] ? content['mep_system_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">MEP System Reviewed by</p>
                </td>
                <td class="font-en">
                  <p class="text-data" v-html="content['mep_system_en'] ? content['mep_system_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-khmer">
                  <p class="symbol">ប្រព័ន្ធបង្ការនិងពន្លត់អគ្គិភ័យត្រួតពិនិត្យនិងបញ្ជាក់ដោយ</p>
                </td>
                <td class="font-khmer">
                  <p class="text-data" v-html="content['fire_km'] ? content['fire_km'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td class="font-en">
                  <p class="symbol">Fire Safety System Inspected by</p>
                </td>
                <td class="font-en">
                  <p class="text-data" v-html="content['fire_en'] ? content['fire_en'] : '-'"></p>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-khmer">
                  <p class="symbol">អនុវត្តការងារសាងសង់ដោយ</p>
                </td>
                <td class="font-khmer">
                  <template v-if=" content['construction_by_km']">
                    <p class="text-data" v-html=" content['construction_by_km']"></p>
                  </template>
                  <template v-else>
                    <p>-</p>
                  </template>
                </td>
              </tr>
              <tr class="bg-table">
                <td class="font-en">
                  <p class="symbol">Building work by</p>
                </td>
                <td class="font-en">
                  <template v-if=" content['construction_by_en']">
                    <p class="text-data" v-html=" content['construction_by_en']"></p>
                  </template>
                  <template v-else>
                    <p>-</p>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-xs-12 col-md-12 text-center font-khmer">
            <p style="font-size: 16px">
              ការប្រើប្រាស់សំណង់ត្រូវអនុលោមតាមបទដ្ឋានគតិយុត្តជាធរមាននៃព្រះរាជាណាចក្រកម្ពុជា។
            </p>
          </div>
          <div class="col-xs-12 col-md-12 text-center font-khmer">
            <p style="font-size: 16px">
              The construction shall be used according to its occupancy and in compliance with the existing
              laws and regulations of Kingdom of Cambodia.
            </p>
          </div>
          <template v-if="content['documents'] && content['documents'].length > 0">
            <div class="col-xs-12">
              <div class="grid-container">
                <template v-for="(item, key) in content['documents']">
                  <template v-if="item.type === 'occupancy_photo_gallery'">
                    <div class="grid-item" :key="key">
                      <a :href="base_api + '/' +item.src" target="_blank" class="thumbnail">
                        <img :src="base_api + '/' + item.src" :alt="item.filename" class="grid__image">
                      </a>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="page-error">
        <div class="page-content">
          <div class="page-error-image">
            <img src="/img/undraw_no_data_qbuo.svg" alt="No Data"/>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="page-error-text">
                <h5>រក​មិន​ឃើញ​ទិន្នន័យ​របស់​វិញ្ញាបនបត្រ​ប្រើ​ប្រាស់​សំណង់</h5>
                <p>Not found data of certificate of occupancy.</p>
                <a href="/" class="btn btn-primary btn-outline-primary text-white">
                  <i class="fa fa-arrow-left"></i> Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from '../../components/Loading'

export default {
  name: 'OccupancyData',
  components: { Loading },
  metaInfo () {
    return {
      title: 'Certificate of Occupancy',
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      base_api: process.env.VUE_APP_API,
      content: null,
      isLoading: false
    }
  },
  methods: {
    showDocument () {
      // window.scrollTo(0, 200);
      this.$router.push({
        name: 'certificate_occupancy_doc',
        param: {
          qr_code: this.$route.params.qr_code
        }
      })
    },
    dateFormatKhmer (date) {
      if (date) {
        return this.$moment(date).locale('km').format('ថ្ងៃទីDD ខែMMMM ឆ្នាំYYYY')
      }
      return 'ថ្ងៃទី ខែ ឆ្នាំ'
    },

    dateFormat (str) {
      return this.$moment(str).locale('en').format('DD MMMM YYYY')
    },

    getDataQr (qr_code) {
      this.isLoading = true
      this.$axios.post(this.base_api + '/api/backend/certificate-occupancy/get-certificate-occupancy-data', {
        qr_code: qr_code
      }).then((response) => {
        this.content = response.data.data
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  mounted () {
    this.getDataQr(this.$route.params.qr_code)
  }
}
</script>

<style scoped>
@import "./../../assets/css/customeTable.css";
</style>
